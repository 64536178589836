export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};


export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.require) {
        isValid = value.trim() !== '' && isValid;
    }
    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }
    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }
    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.isFloat) {
        const pattern = /[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.isSelected) {
        isValid = value !== 'Nada' && isValid
    }
    return isValid;
}

export const dateWithHours = (date) => {
    const year = date.getFullYear()
    var month = '0'
    var monthNumber = date.getMonth() + 1;
    if (date.getMonth() < 10) {
        month = '0' + (monthNumber);
    } else {
        month = monthNumber;
    }
    var day = '0'
    if (date.getDate() < 10) {
        day = '0' + date.getDate()
    } else {
        day = date.getDate()
    }
    var hour = '0'
    if (date.getHours() < 10) {
        hour = '0' + date.getHours()
    } else {
        hour = date.getHours()
    }
    var minute = '0'
    if (date.getMinutes() < 10) {
        minute = '0' + date.getMinutes()
    } else {
        minute = date.getMinutes()
    }
    var second = '0'
    if (date.getSeconds() < 10) {
        second = '0' + date.getSeconds()
    } else {
        second = date.getSeconds()
    }
    const DateToDB = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
    return DateToDB;
}
