import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBdPAvBH8-04lCp_ThpFbNrvs6rW4h8Isw",
    authDomain: "numerologiamx.firebaseapp.com",
    projectId: "numerologiamx",
    storageBucket: "numerologiamx.appspot.com",
    messagingSenderId: "975750283348",
    appId: "1:975750283348:web:cd8221e5af063e36036dc1",
    measurementId: "G-3JJDBTBBF5"
};

const appFBS = initializeApp(firebaseConfig);
export const dbFBS = getFirestore(appFBS);
export const authFBS = getAuth(appFBS);
export default appFBS;