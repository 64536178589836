import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import asyncComponent from './hoc/asyncComponent/asyncComponent';
import Layout from './hoc/layout/layout';
import * as actions from './store/actions/index';
import { connect } from 'react-redux';

const asyncHomePage = asyncComponent(() => {
  return import('./containers/Home/HomePage');
})

const asynResultPage = asyncComponent(() => {
  return import('./containers/Results/ResultPage');
})

const asyncLoginPage = asyncComponent(() => {
  return import('./containers/Auth/AuthPage');
})

const asyncMisReportes = asyncComponent(() => {
  return import('./containers/MisReportes/MisReportesPage');
})

const asyncPerfilesPage = asyncComponent(() => {
  return import('./containers/Perfil/PerfilPage');
})

const asyncServiciosPage = asyncComponent(() => {
  return import('./containers/Servicios/ServiciosPage');
})

class App extends Component {
  
  componentDidMount() {
    this.props.onTryAuthSignup();
  }

  render() {
    let routes = (
      <Switch>
        <Route path='/servicios' component={asyncServiciosPage}/>
        <Route path='/login' component={asyncLoginPage}/>
        <Route path='/resultados' component={asynResultPage} />
        <Route path='/' exact component={asyncHomePage} />
        <Redirect to='/' />
      </Switch>
    )

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          <Route path='/misreportes' component={asyncMisReportes} />
          <Route path='/perfil' component={asyncPerfilesPage} />          
          <Route path='/resultados' component={asynResultPage} />
          <Route path='/' exact component={asyncHomePage} />
          <Redirect to='/' />
        </Switch>
      )
    }
    return (
      <div>
        <Layout>
          {routes}
        </Layout>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAuthSignup: () => dispatch(actions.authCheckState())
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.FBSauthReducer.tokenFBS !== null,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
