import React, { Component } from "react";
import Auxfile from '../Aux_folder/Auxfile';

class Layout extends Component{
    render(){
        return(
            <Auxfile>
                <main>
                    {this.props.children}
                </main>
            </Auxfile>
        )
    }
}

export default Layout;